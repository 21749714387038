import React from 'react'
import styled from 'styled-components'

import Layout from '../layouts'

import MetaHeader from '../components/MetaHeader'
import PageWrapper from '../components/PageWrapper'
import Button from '../components/Button'

import { FormSection } from '../components/common/form'

import {
  Checkbox,
  Input,
  OtherInput,
  Radio,
  Textarea,
} from '../components/common/form'

const ApplicationForm = styled.form.attrs({
  name: 'AngelAcademyRegistration2',
  method: 'POST',
  'data-netlify': 'true',
  'data-netlify-honeypot': 'bot-field',
  action: '/contact-success',
})`
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
`

const CohortRegistrationPage = () => (
  <Layout>
    <MetaHeader title="Female Funders Application - Step 2" />
    <PageWrapper>
      <section>
        <h3>Cohort Registration</h3>
        <p>Thank you for your interest in Female Funders.</p>
        <p>
          Please complete the following application form to be considered to
          join Angel Academy. We’ll be in touch within 1-2 weeks of submission.
        </p>
      </section>

      <ApplicationForm>
        <input
          type="hidden"
          name="form-name"
          value="AngelAcademyRegistration2"
        />

        <FormSection title="Contact">
          <Input name="firstName" label="First Name" />
          <Input name="lastName" label="Last Name" />
          <Input name="email" label="Email" type="email" />
          <Input name="phone" label="Phone Number" />
          <Input name="role" label="Role" />
          <Input name="company" label="Company" />
        </FormSection>

        <FormSection title="About">
          <Radio
            horizontal
            name="knowledge"
            label="How would you rate your knowledge of angel investing?"
            options={[
              { label: '0', value: '0' },
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
            ]}
          />
          <Radio
            name="experience"
            label="What is your investment experience to date?"
            options={[
              {
                value: '0',
                label: 'I have never invested in a private company',
              },
              { value: '1', label: '1-3 investments' },
              { value: '2', label: '4-10 investments' },
              { value: '3', label: '11+ investments' },
            ]}
          />
          <Radio
            name="investment"
            label="How much are you considering investing in the next 12 months (across 1 or more companies)?"
            options={[
              { value: '0', label: '$0' },
              { value: '1', label: '$5,000 - $10,000' },
              { value: '2', label: '$10,001 - $25,000' },
              { value: '3', label: '$25,001 - $50,000' },
              { value: '4', label: '$50,001 - $100,000' },
              { value: '5', label: '$100,001+' },
              { value: '6', label: 'Unsure' },
            ]}
          />
          <Checkbox
            name="interest[]"
            label="Why are you interested in the Female Funders program?"
            options={[
              { value: 'education', label: 'Education' },
              {
                value: 'support',
                label: 'To support companies at an early stage',
              },
              {
                value: 'join movement',
                label: 'To join a movement to shape the future',
              },
              {
                value: 'new skillset',
                label: 'To learn a new skillset that I can apply to my job',
              },
              {
                value: 'more involved',
                label:
                  'I want to get more involved in a specific industry or vertical',
              },
              {
                value: 'women led',
                label: 'I want to invest in women-led ventures',
              },
              {
                value: 'return',
                label: 'Learn how to make a return on my money',
              },
              {
                value: 'networking',
                label:
                  'Connect with others for potential co-investment opportunities',
              },
              {
                value: 'opportunity',
                label:
                  'Opportunity to support companies through capital and sharing of expertise',
              },
              {
                value: 'access',
                label:
                  'Access to investment opportunities in early stage companies',
              },
              { value: 'other', label: 'Other' },
            ]}
          />
          <Textarea
            name="bio"
            label="Tell us about yourself and your background or insert your bio"
          />
          <Textarea
            name="programExperience"
            label="What does a successful program experience look like for you?"
          />
          <Input
            name="aboutSource"
            label="How did you hear about Female Funders?"
          />
          <Radio
            name="status"
            label="I am an:"
            options={[
              { label: 'Entrepreneur', value: 'entrepreneur' },
              { label: 'Aspiring investor', value: 'aspiring investor' },
              { label: 'Existing investor', value: 'existing investor' },
              {
                label: 'Other:',
                value: 'other',
                children: <OtherInput name="otherStatus" label="" />,
              },
            ]}
          />
          <Radio
            name="investor"
            label="Are you an accredited investor?"
            caption="To be considered an accredited investor, one must have an income of at least $200,000 (or $300,000 combined income if married) OR have an individual net worth (or joint net worth with spouse) exceeding $1 million, excluding one’s primary residence"
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
              { label: 'I am unsure', value: 'unsure' },
            ]}
          />
          <div data-netlify-recaptcha="true" />
        </FormSection>
        <Button primary submit>
          Submit
        </Button>
      </ApplicationForm>
    </PageWrapper>
  </Layout>
)

export default CohortRegistrationPage
